<template>
    <div class="layout">
        <div class="layout-header"></div>
        <div class="layout-banner"></div>
        <div class="container1500 mh500">
            <div class="layout-main-top"></div>
            <div class="layout-main-middle">
                <div class="title">
                    <p></p>
                    <p><span>公司实力</span></p>
                    <p></p>
                </div>
                <p class="title-en">Company strength</p>
                <h2>● 科研技术力量</h2>
                <div class="img1"></div>
                <div class="img2"></div>
                <div class="img3"></div>
                <h2>● 资质证书</h2>
                <p>· 高新技术企业</p>
                <div class="img4"></div>
                <p>· 市政公用工程施工总承包资质</p>
                <div class="img5"></div>
                <p>· 测绘资质证书</p>
                <div class="img6"></div>
                <p>· ISO管理体系认证</p>
                <div class="img7"></div>
                <!-- <p>· 检验检测机构资质认定证书</p>
                <div class="img8"></div> -->
                <!-- <p>· 劳务分包资质证书</p>
                <div class="img9"></div> -->
                <!-- <p>· 荣誉证书</p>
                <div class="img10"></div> -->
                <h2>● 10+ 发明专利及实用新型专利</h2>
                <div class="img11"></div>
                <h2>● 10+ 软件著作权</h2>
                <div class="img12"></div>
            </div>
        </div>
        <NavFooter />
    </div>
</template>
<script>
import NavFooter from "../../components/NavFooter.vue";

export default {
    name: "CompanyProfile",
    components: {
        NavFooter,
    },
    mounted() {},
};
</script>
<style lang="scss" scoped>
@import "../../assets/scss/mixin.scss";
.layout {
    position: relative;
    width: 100%;
    z-index: 0;
    .layout-header {
        height: 100px;
    }
    .layout-banner {
        width: 100%;
        height: 350px;
        background: url("../../assets/img/banner4.jpg") no-repeat center;
        background-size: cover;
    }
    .mh500 {
        min-height: 500px;
        .layout-main-top {
            height: 40px;
            border-bottom: 1px solid #bcbcbc50;
        }
        .layout-main-middle {
            padding: 40px 0;
            min-height: 460px;
            box-sizing: border-box;
            .title {
                width: 100%;
                height: 39px;
                display: flex;
                justify-content: center;
                p {
                    width: 20px;
                    height: 19px;
                    font-size: 26px;
                    border-bottom: 1px solid #0e2686;
                }
                :nth-child(2) {
                    width: 426px;
                    height: 39px;
                    line-height: 39px;
                    text-align: center;
                    border-bottom: none;
                }
            }
            .title-en {
                width: 100%;
                height: 23.5px;
                line-height: 23.5px;
                font-size: 15px;
                color: #0e2686;
                text-align: center;
            }
            h2 {
                margin: 10px 0;
            }
            p {
                height: 40px;
                line-height: 40px;
                font-size: 15px;
            }
            .img1 {
                width: 1100px;
                height: 500px;
                margin: 10px auto;
                background-image: url("/img/strength/1-1.jpg");
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
            .img2 {
                width: 1100px;
                height: 500px;
                margin: 10px auto;
                background-image: url("/img/strength/1-2.jpg");
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
            .img3 {
                width: 1000px;
                height: 500px;
                margin: 10px auto;
                background-image: url("/img/strength/1-3.png");
                background-repeat: no-repeat;
                background-size: 101% 101%;
            }
            .img4 {
                width: 1100px;
                height: 700px;
                margin: 0 auto;
                background-image: url("/img/strength/1-4.png");
                background-repeat: no-repeat;
                background-size: 100% 100%;
                // background-position: 40%;
            }
            .img5 {
                width: 1000px;
                height: 500px;
                margin: 0 auto;
                background-image: url("/img/strength/1-5.png");
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
            .img6 {
                width: 700px;
                height: 500px;
                margin: 0 auto;
                background-image: url("/img/strength/1-6.png");
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
            .img7 {
                width: 1000px;
                height: 500px;
                margin: 0 auto;
                background-image: url("/img/strength/1-7.png");
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
            .img8 {
                width: 700px;
                height: 600px;
                margin: 0 auto;
                background-image: url("/img/strength/1-8.jpg");
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
            .img9 {
                width: 700px;
                height: 600px;
                margin: 0 auto;
                background-image: url("/img/strength/1-9.jpg");
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
            .img10 {
                width: 1100px;
                height: 500px;
                margin: 0 auto;
                background-image: url("/img/strength/1-10.jpg");
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
            .img11 {
                width: 1100px;
                height: 500px;
                margin: 0 auto;
                background-image: url("/img/strength/1-11.png");
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
            .img12 {
                width: 1100px;
                height: 400px;
                margin: 0 auto;
                background-image: url("/img/strength/1-12.png");
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
        }
    }
}
</style>